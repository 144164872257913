import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { weightToKgs, fromKgsToPounds } from './calc';
import { getAmousConfigByNameFromWindow } from './window';
//////////////////////////////////////////////////

const mapSearchedItem = R.curry((item: Object) => R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR, item));

const getDimensionInFeet = (dimension: any, uom: string) => {
  const map = {
    [GC.UOM_FOOT]: () => dimension,
    [GC.UOM_INCH]: () => R.divide(dimension, 12),
    [GC.UOM_METER]: () => R.multiply(dimension, 3.281),
    [GC.UOM_CENTIMETER]: () => R.divide(dimension, 30.48),
  };

  return map[uom]();
};

const getDimensionInInches = (dimension: any, uom: string) => {
  const map = {
    [GC.UOM_INCH]: () => dimension,
    [GC.UOM_FOOT]: () => R.multiply(dimension, 12),
    [GC.UOM_METER]: () => R.multiply(dimension, 39.3701),
    [GC.UOM_CENTIMETER]: () => R.divide(dimension, 0.393701),
  };

  return map[uom]();
};

const getDimensionInMeters = (dimension: any, uom: string) => {
  const map = {
    [GC.UOM_METER]: () => dimension,
    [GC.UOM_FOOT]: () => R.divide(dimension, 3.281),
    [GC.UOM_INCH]: () => R.multiply(dimension, 0.0254),
    [GC.UOM_CENTIMETER]: () => R.divide(dimension, 100),
  };

  return map[uom]();
};

const getDimensionInCentimeters = (dimension: any, uom: string) => {
  const map = {
    [GC.UOM_CENTIMETER]: () => dimension,
    [GC.UOM_INCH]: () => R.multiply(dimension, 2.54),
    [GC.UOM_METER]: () => R.multiply(dimension, 100),
    [GC.UOM_FOOT]: () => R.divide(dimension, 0.03281),
  };

  return map[uom]();
};

const convertDimensionValueByUoms = (value: number, fromUom: string, toUom: string) => {
  if (R.equals(toUom, GC.UOM_FOOT)) return getDimensionInFeet(value, fromUom);

  if (R.equals(toUom, GC.UOM_INCH)) return getDimensionInInches(value, fromUom);

  if (R.equals(toUom, GC.UOM_METER)) return getDimensionInMeters(value, fromUom);

  if (R.equals(toUom, GC.UOM_CENTIMETER)) return getDimensionInCentimeters(value, fromUom);
};

const getWeightInLbs = (weight: any, uom: string) => {
  const map = {
    [GC.UOM_POUND]: () => weight,
    [GC.UOM_KILOGRAM]: () => fromKgsToPounds(weight),
    [GC.UOM_TON_US]: () => fromKgsToPounds(weightToKgs({ from: GC.UOM_TON_US, value: weight })),
    [GC.UOM_METRIC_TON]: () => fromKgsToPounds(weightToKgs({ from: GC.UOM_METRIC_TON, value: weight })),
  };

  return map[uom]();
};

const calculateFreightClass = (data: Object) => {
  const {
    weight,
    weightType,
    dimensionsW,
    dimensionsL,
    dimensionsH,
    dimensionsUOM,
  } = data;

  const freightClassCalculator = getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR);

  const width = getDimensionInFeet(dimensionsW, dimensionsUOM);
  const length = getDimensionInFeet(dimensionsL, dimensionsUOM);
  const height = getDimensionInFeet(dimensionsH, dimensionsUOM);

  const volume = width * length * height;

  const weightToUse = getWeightInLbs(weight, weightType);

  const lbsPerCubicFeet = R.divide(weightToUse, volume);

  let value = '';

  if (R.equals(freightClassCalculator, GC.FREIGHT_CLASS_CALCULATOR_STANDARD)) {
    switch (true) {
      case R.gte(lbsPerCubicFeet, 50):
        value = 'FIFTY';
        break;
      case R.gte(lbsPerCubicFeet, 35):
        value = 'FIFTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 30):
        value = 'SIXTY';
        break;
      case R.gte(lbsPerCubicFeet, 22.5):
        value = 'SIXTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 15):
        value = 'SEVENTY';
        break;
      case R.gte(lbsPerCubicFeet, 13.5):
        value = 'SEVENTY_SEVEN_POINT_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 12):
        value = 'EIGHTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 10.5):
        value = 'NINETY_TWO_POINT_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 9):
        value = 'ONE_HUNDRED';
        break;
      case R.gte(lbsPerCubicFeet, 8):
        value = 'ONE_HUNDRED_TEN';
        break;
      case R.gte(lbsPerCubicFeet, 7):
        value = 'ONE_HUNDRED_TWENTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 6):
        value = 'ONE_HUNDRED_FIFTY';
        break;
      case R.gte(lbsPerCubicFeet, 5):
        value = 'ONE_HUNDRED_SEVENTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 4):
        value = 'TWO_HUNDRED';
        break;
      case R.gte(lbsPerCubicFeet, 3):
        value = 'TWO_HUNDRED_FIFTY';
        break;
      case R.gte(lbsPerCubicFeet, 2):
        value = 'THREE_HUNDRED';
        break;
      case R.gte(lbsPerCubicFeet, 1):
        value = 'FOUR_HUNDRED';
        break;
      case R.lt(lbsPerCubicFeet, 1):
        value = 'FIVE_HUNDRED';
        break;
      default:
        value = '';
    }
  } else if (R.equals(freightClassCalculator, GC.FREIGHT_CLASS_CALCULATOR_FAK)) {
    switch (true) {
      case R.gte(lbsPerCubicFeet, 30):
        value = 'SIXTY';
        break;
      case R.gte(lbsPerCubicFeet, 22.5):
        value = 'SIXTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 15):
        value = 'SEVENTY';
        break;
      case R.gte(lbsPerCubicFeet, 12):
        value = 'EIGHTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 10):
        value = 'NINETY_TWO_POINT_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 8):
        value = 'ONE_HUNDRED';
        break;
      case R.gte(lbsPerCubicFeet, 6):
        value = 'ONE_HUNDRED_TWENTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 4):
        value = 'ONE_HUNDRED_SEVENTY_FIVE';
        break;
      case R.gte(lbsPerCubicFeet, 2):
        value = 'TWO_HUNDRED_FIFTY';
        break;
      case R.gte(lbsPerCubicFeet, 1):
        value = 'THREE_HUNDRED';
        break;
      case R.gte(lbsPerCubicFeet, 0):
        value = 'FOUR_HUNDRED';
        break;
      default:
        value = '';
    }
  }

  return value;
};

export {
  mapSearchedItem,
  calculateFreightClass,
  convertDimensionValueByUoms,
};
