
import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { getPropFromObject } from './getter';
import { createStringFromArray } from './string';
import { statesMap, countriesMap } from './options';
import { mapObjectEmptyStringFieldsToNull } from './array-object';
import {
  isTrue,
  ifElse,
  isNotNil,
  isNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const getShortName = (name: string, data: Object) => {
  let shortName = R.pathOr(name, [name, 'shortName'], data);

  if (R.equals(shortName, 'undefined')) {
    shortName = name;
  }

  return shortName;
};

const renderFullAddressString = (location: Object) => (
  R.compose(
    R.join(', '),
    R.filter((item: string) => isNotNil(item)),
    R.values(),
    R.assoc('state', getShortName(location.state, statesMap)),
    R.assoc('country', getShortName(location.country, countriesMap)),
    R.pick(['address1', 'address2', 'city', 'state', 'zip', 'country']),
  )(location)
);

const renderSmallAddressString = (location: Object) => (
  R.compose(
    R.join(', '),
    R.filter((item: string) => isNotNil(item)),
    R.values(),
    R.assoc('state', getShortName(location.state, statesMap)),
    R.assoc('country', getShortName(location.country, countriesMap)),
    R.pick(['city', 'state', 'zip', 'country']),
  )(location)
);

const renderAddressNameAndCode = (location: Object = {}) => (
  R.pathOr('', ['locationName'], location)
);

const renderAddressInfoWithNameAndCode = (
  location: Object = {},
  withCode: boolean = true,
  withName: boolean = true,
) => {
  const { templateId, locationName, address1, address2 } = location;

  let address = R.or(address1, '');

  if (isNotNilAndNotEmpty(address2)) {
    address = `${address1}, ${address2}`;
  }

  if (R.and(withCode, templateId)) {
    address = `${templateId}, ${address}`;
  }

  if (R.and(withName, locationName)) {
    address = `${locationName}, ${address}`;
  }

  return address;
};

const concatLocationFirstAndSecondAddresses = (location: Object = {}) => {
  if (R.isNil(R.prop(GC.FIELD_ADDRESS_2, location))) {
    return R.propOr('', GC.FIELD_ADDRESS_1, location);
  }

  return `${R.propOr('', GC.FIELD_ADDRESS_1, location)}, ${R.prop(GC.FIELD_ADDRESS_2, location)}`;
};

const concatLocationFields = (entity: Object, fields: Array) => {
  if (R.isNil(entity)) return '';

  const defaultFields = [GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_ZIP, GC.FIELD_COUNTRY];

  const fieldsToPick = ifElse(
    isNilOrEmpty(fields),
    defaultFields,
    fields,
  );

  const locationValuesArr = R.values(
    R.pick(fieldsToPick, entity),
    entity,
  );

  return createStringFromArray(locationValuesArr, ', ');
};

const concatContactFields = (entity: Object, fields: Array) => {
  if (R.isNil(entity)) return '';

  const defaultFields = ['contactName', 'email', 'phone'];

  const fieldsToPick = ifElse(
    isNilOrEmpty(fields),
    defaultFields,
    fields,
  );

  const contactValuesArr = R.values(
    R.pick(fieldsToPick, entity),
    entity,
  );

  return createStringFromArray(contactValuesArr, ', ');
};

const mergeFirstContactWithLocation = (location: Object, useMultiEmails: boolean = false) => {
  let contact = R.compose(
    R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
    R.pathOr({}, ['contacts', 0]),
  )(location);

  if (isTrue(useMultiEmails)) {
    const emails = ifElse(
      isNotNilAndNotEmpty(getPropFromObject(GC.FIELD_EMAIL, contact)),
      R.of(Array, getPropFromObject(GC.FIELD_EMAIL, contact)),
      [],
    );

    contact = R.assoc(GC.FIELD_EMAILS, emails, contact);
  }

  return R.mergeRight(location, contact);
};

const setLocationContactFieldsToContactsArr = (location: Object) => {
  const firstContactPath = [GC.FIELD_CONTACTS, 0];
  const contactFields = R.pick(GC.GROUPED_FIELDS.EVENT_CONTACT_ARR, location);
  const contactFromArr = R.pathOr({}, firstContactPath, location);
  const contact = mapObjectEmptyStringFieldsToNull(R.mergeRight(contactFromArr, contactFields));
  const contacts = R.of(contact);

  return R.assoc(
    GC.FIELD_CONTACTS,
    contacts,
    R.omit(GC.GROUPED_FIELDS.EVENT_CONTACT_ARR, location),
  );
};

const getTemplateIdFromLocation = (location: Object) => {
  const tId = R.path([GC.FIELD_TEMPLATE_ID, GC.FIELD_TEMPLATE_ID], location);
  const tId1 = R.path([GC.FIELD_TEMPLATE_ID, 'value'], location);
  const tId2 = R.path([GC.FIELD_TEMPLATE_ID], location);

  return R.or(tId, R.or(tId1, tId2));
};

const resetTemplateIdToLocation = (location: Object) => R.assoc(
  GC.FIELD_TEMPLATE_ID, getTemplateIdFromLocation(location), location,
);

const getLocationTypeFromLocation = (location: Object) => {
  const type1 = R.path([GC.FIELD_LOCATION_TYPE, 'value'], location);
  const type2 = R.path([GC.FIELD_LOCATION_TYPE], location);

  return R.or(type1, type2);
};

const resetLocationTypeToLocation = (location: Object) => R.assoc(
  GC.FIELD_LOCATION_TYPE, getLocationTypeFromLocation(location), location,
);

const resetLocationTypeFromDropdownOption = (location: Object) => {
  const type1 = R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], location);
  const type2 = R.path([GC.FIELD_LOCATION_TYPE], location);

  return R.assoc(
    GC.FIELD_LOCATION_TYPE, R.or(type1, type2), location,
  );
};

const mapSearchedLocation = R.curry((location: Object) => {
  if (isNilOrEmpty(location)) return location;

  const locationWithoutSystem = R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR, location);
  const locationWithContacts = mergeFirstContactWithLocation(locationWithoutSystem);
  const locationType = R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], location);

  return R.assoc(GC.FIELD_LOCATION_TYPE, locationType, R.omit(GC.FIELD_CONTACTS, locationWithContacts));
});

const mapSearchedLocation2 = (location: Object, options: Object) => {
  if (isNilOrEmpty(location)) return location;

  const locationWithoutSystem = R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR, location);

  const locationWithContacts = mergeFirstContactWithLocation(
    locationWithoutSystem,
    getPropFromObject('useMultiEmails', options),
  );

  const locationType = R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], location);

  return R.assoc(GC.FIELD_LOCATION_TYPE, locationType, R.omit(GC.FIELD_CONTACTS, locationWithContacts));
};

const mapSearchedLocationWithContactsArr = R.curry((location: Object) => {
  if (isNilOrEmpty(location)) return location;

  const locationWithoutSystem = R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR, location);

  const contacts = R.map(
    R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR),
    R.pathOr([], [GC.FIELD_CONTACTS], location),
  );

  const locationType = R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], location);

  const operationHour = R.compose(
    R.map(R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_FULL_ARR)),
    R.pathOr([], [GC.FIELD_OPERATION_HOUR]),
  )(location);

  return R.mergeRight(locationWithoutSystem, { contacts, locationType, operationHour });
});

const getLocationOptions = (locations: Array) => R.map((location: Object) => ({
  value: location[GC.FIELD_TEMPLATE_ID],
  label: `${location[GC.FIELD_TEMPLATE_ID]}, ${location[GC.FIELD_LOCATION_NAME]}, ${
    concatLocationFirstAndSecondAddresses(location)} ${concatLocationFields(location)}`,
}), locations);

const getTransformLocationsConfigs = () => ([
  { transform: '', main: true },
  {
    transform: 'rotate(72deg) translate(82%, -60%)',
    infoTransform: 'translate(-33%, calc(30px - 100%))',
  },
  {
    transform: 'rotate(144deg) translate(50%, -155%)',
    infoTransform: 'translate(-45%, calc(60px - 100%))',
  },
  {
    transform: 'rotate(216deg) translate(-50%, -155%)',
    infoTransform: 'translate(-55%, calc(60px - 100%))',
  },
  {
    transform: 'rotate(288deg) translate(-82%, -60%)',
    infoTransform: 'translate(-66%, calc(30px - 100%))',
  },
  {
    transform: 'rotate(324deg) translate(-50%, -100%)',
    infoTransform: 'translate(-70%, calc(-15px - 100%))',
  },
  {
    transform: 'rotate(36deg) translate(50%, -100%)',
    infoTransform: 'translate(-30%, calc(-15px - 100%))',
  },
  {
    transform: 'rotate(108deg) translate(82%,-200%)',
    infoTransform: 'translate(-25%, calc(60px - 100%))',
  },
  {
    transform: 'rotate(180deg) translate(0%,-260%)',
    infoTransform: 'translate(-50%, calc(90px - 100%))',
  },
  {
    transform: 'rotate(252deg) translate(-82%,-200%)',
    infoTransform: 'translate(-75%, calc(60px - 100%))',
  },
]);

const makeGroupedLocationsByLatLng = (locations: Array) => {
  if (isNilOrEmpty(locations)) return [];

  let grouped = [];
  let lastLocations = [...locations];

  const makeOnSamePositionLocations = () => {
    const firstLocationsLatLng = R.path(['latLng'], R.head(lastLocations));

    const onSamePositionLocations = R.filter(
      (location: Object) => R.equals(location.latLng, firstLocationsLatLng),
      lastLocations,
    );

    grouped = R.append(onSamePositionLocations, grouped);
    lastLocations = R.without(onSamePositionLocations, lastLocations);

    if (isNotNilAndNotEmpty(lastLocations)) {
      makeOnSamePositionLocations();
    }
  };

  makeOnSamePositionLocations();

  return grouped;
};

const makeLocationsWithTransform = (locations: Array) => {
  const transformConfigs = getTransformLocationsConfigs();
  const groupedLocations = makeGroupedLocationsByLatLng(locations);

  const locationsWithTransform = R.reduce(
    (allLocations: Array, locationGroup: Array) => {
      const mapped = locationGroup.map(
        (location: Object, index: number) => ({
          ...location,
          main: isTrue(R.path([index, 'main'], transformConfigs)),
          transform: R.path([index, 'transform'], transformConfigs),
          infoTransform: R.path([index, 'infoTransform'], transformConfigs),
        }),
      );

      return R.concat(allLocations, mapped);
    },
    [],
    groupedLocations,
  );

  return R.sort((item: Object) => item.main, locationsWithTransform);
};

const getCountryAndStateByStringValues = ({ state, country }: Object) => {
  const countryValue = R.prop(country, {
    'Canada': GC.ENUM_CA,
    'Mexico': GC.ENUM_MX,
    'United States': GC.ENUM_US,
  });

  const stateValue = R.compose(
    R.pathOr('', [state, GC.FIELD_VALUE]),
    R.indexBy(R.prop('labelFull')),
    R.prop(countryValue),
  )(GC.STATE_OPTIONS_MAP);

  return [countryValue, stateValue];
};

const getShortStateFromLocation = ({ state, country }: Object) => {
  const countryValue = R.prop(country, {
    'Canada': GC.ENUM_CA,
    'Mexico': GC.ENUM_MX,
    'United States': GC.ENUM_US,
  });

  const stateValue = R.compose(
    R.pathOr('', [state, 'label']),
    R.indexBy(R.prop('labelFull')),
    R.prop(countryValue),
  )(GC.STATE_OPTIONS_MAP);

  if (isNilOrEmpty(stateValue)) return state;

  return stateValue;
};

export {
  getShortName,
  getLocationOptions,
  mapSearchedLocation,
  concatContactFields,
  mapSearchedLocation2,
  concatLocationFields,
  renderFullAddressString,
  renderSmallAddressString,
  renderAddressNameAndCode,
  getTemplateIdFromLocation,
  resetTemplateIdToLocation,
  getShortStateFromLocation,
  makeLocationsWithTransform,
  resetLocationTypeToLocation,
  getLocationTypeFromLocation,
  makeGroupedLocationsByLatLng,
  getTransformLocationsConfigs,
  mergeFirstContactWithLocation,
  renderAddressInfoWithNameAndCode,
  getCountryAndStateByStringValues,
  mapSearchedLocationWithContactsArr,
  resetLocationTypeFromDropdownOption,
  concatLocationFirstAndSecondAddresses,
  setLocationContactFieldsToContactsArr,
};
