import * as R from 'ramda';
import trim from 'voca/trim';
import { toastr } from 'react-redux-toastr';
import { getGeocode } from 'use-places-autocomplete';
// constants
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
// helpers
import { logToSystem } from './log';
import { handleException } from './ajax';
import { getWindowLocale } from './locale';
import { getPropFromObject } from './getter';
import { isMetricUomSystem } from './config';
import { getCurrentBranchGuid } from './storage';
import { renameKeys, mapObjectNullFieldsToEmptyStrings } from './array-object';
import {
  isTrue,
  ifElse,
  isString,
  isNotNil,
  getOrElse,
  callFunction,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////
const GeoPoint = require('geopoint');
//////////////////////////////////////////////////

const { is } = Object;

const makeGoogleAPILinkWithKey = (key: string) => (
  `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,drawing,places&language=en`
);

function getGeolocation() {
  return new Promise((resolve: Object, reject: Object) =>
    window.navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 4000,
      enableHighAccuracy: true,
    }));
}


function googleMapsGeocode(coords: Object, componentName: any) {
  const { geocode } = new google.maps.Geocoder();

  const { latitude, longitude } = coords;

  const location = new google.maps.LatLng(latitude, longitude);

  logToSystem(GC.LOG_MESSAGE_TYPE_GOOGLE_MAPS_GEOCODER, { componentName, from: 'helpers googleMapsGeocode' });

  return new Promise((resolve: Object, reject: Object) => {
    geocode({ location }, ([result]: Array, status: string) => {
      if (is(status, 'OK')) {
        if (isNotNilAndNotEmpty(result)) {
          return resolve(result);
        }

        toastr.info(getWindowLocale('messages:no-results-found', 'No results found'));
      } else {
        toastr.info(getWindowLocale(
          'messages:select-unidentified-location',
          'You have selected a unidentified location. Please, try selecting another location.',
        ));
      }

      reject(status);
    });
  });
}

const getDistanceBetweenTwoPoints = (point1: Object, point2: Object, inKm: any = false) => {
  const inKilemeters = R.or(inKm, isMetricUomSystem());

  const latitude1 = getPropFromObject(GC.FIELD_LATITUDE, point1);
  const longitude1 = getPropFromObject(GC.FIELD_LONGITUDE, point1);
  const latitude2 = getPropFromObject(GC.FIELD_LATITUDE, point2);
  const longitude2 = getPropFromObject(GC.FIELD_LONGITUDE, point2);

  const point1Geo = new GeoPoint(latitude1, longitude1);
  const point2Geo = new GeoPoint(latitude2, longitude2);

  return point1Geo.distanceTo(point2Geo, inKilemeters);
};

const getSpecificLocationValue = (
  components: google.maps.GeocoderAddressComponent[],
  type: string,
  alternateType: string,
) => {
  const addressComponent = R.find((component: Object) => (
    isNotNil(
      R.find((componentType: string) => (
        R.or(R.equals(type, componentType), R.equals(alternateType, componentType))
      ),
      component.types,
    ))),
    components,
  );

  return getOrElse(addressComponent, 'long_name', '');
};

const getGoogleGeocodeByAddressDataFromResult = (
  result: Object,
  useFormattedAddress: boolean,
  addressFieldName: string,
) => {
  const {
    geometry,
    formatted_address,
    address_components,
  } = result;

  const data = address_components; // eslint-disable-line
  const longitude = geometry.location.lng();
  const latitude = geometry.location.lat();
  const formattedAddress = formatted_address; // eslint-disable-line
  const route = getSpecificLocationValue(data, 'route');
  const country = getSpecificLocationValue(data, 'country');
  const zip = getSpecificLocationValue(data, 'postal_code');
  const streetNumber = getSpecificLocationValue(data, 'street_number');
  const city = getSpecificLocationValue(data, 'locality', 'postal_town');
  const state = getSpecificLocationValue(data, 'administrative_area_level_1');

  const address1 = trim(ifElse(
    isTrue(useFormattedAddress),
    formattedAddress,
    `${streetNumber} ${route}`,
  ));

  const resultObject = {
    zip,
    city,
    state,
    country,
    address1,
    latitude,
    longitude,
    formattedAddress,
  };

  return ifElse(
    isString(addressFieldName),
    renameKeys({ 'address1': addressFieldName }, resultObject),
    resultObject,
  );
};

const getGeocodeFieldsFromAPIRes = (
  searchAddress: string,
  data: Object,
  useFormattedAddress: boolean,
  addressFieldName: string,
) => {
  const {
    zip,
    city,
    state,
    country,
    latitude,
    longitude,
    streetName,
    streetNumber,
  } = data;

  const formattedAddress = searchAddress;

  const address1 = trim(ifElse(
    isTrue(useFormattedAddress),
    formattedAddress,
    `${streetNumber} ${streetName}`,
  ));

  const resultObject = {
    zip,
    city,
    state,
    country,
    address1,
    latitude,
    longitude,
    formattedAddress,
  };

  return ifElse(
    isString(addressFieldName),
    renameKeys({ 'address1': addressFieldName }, resultObject),
    resultObject,
  );
};

const geocodeByPlaceAddress = async (
  address: string,
  componentName: any,
  catchCallback: Function,
  options: any,
) => {
  try {
    logToSystem(
      GC.LOG_MESSAGE_TYPE_DISTANCE_SERVICE_GEOFENCING_ADDRESS,
      {
        address,
        componentName,
        from: 'helpers geocodeByPlaceAddress',
      },
    );

    const useFormattedAddress = R.path(['useFormattedAddress'], options);
    const addressFieldName = R.path(['addressFieldName'], options);

    // TODO: with config or remove condition logic
    const useAPI = true;

    if (isTrue(useAPI)) {
      const enterpriseGuid = getCurrentBranchGuid();

      const { data } = await sendRequest(
        'post',
        endpointsMap.distanceGeofencingLocationInfoByAddress,
        { data: { address, enterpriseGuid } },
      );

      return getGeocodeFieldsFromAPIRes(
        address,
        mapObjectNullFieldsToEmptyStrings(data),
        useFormattedAddress,
        addressFieldName,
      );
    }

    if (isString(componentName)) {
      logToSystem(GC.LOG_MESSAGE_TYPE_GOOGLE_GEOCODE_API, { componentName, from: 'helpers geocodeByPlaceAddress' });
    }

    const results = await getGeocode({ address });

    return getGoogleGeocodeByAddressDataFromResult(R.head(results), useFormattedAddress, addressFieldName);
  } catch (error) {
    callFunction(catchCallback);

    handleException(error, 'geocodeByAddress');
  }
};

const geocodeByPlaceAddressWithTokenAndEnterpriseGuid = async (
  address: string,
  componentName: any,
  catchCallback: Function,
  options: any,
) => {
  try {
    logToSystem(
      GC.LOG_MESSAGE_TYPE_DISTANCE_SERVICE_GEOFENCING_ADDRESS,
      {
        componentName,
        from: 'helpers geocodeByPlaceAddressWithTokenAndEnterpriceGuid',
      },
    );

    const carrierToken = R.path(['carrierToken'], options);
    const enterpriseGuid = R.path(['enterpriseGuid'], options);
    const addressFieldName = R.path(['addressFieldName'], options);
    const useFormattedAddress = R.path(['useFormattedAddress'], options);

    // TODO: with config or remove condition logic
    const useAPI = true;

    if (isTrue(useAPI)) {
      const { data } = await sendRequest(
        'post',
        endpointsMap.getTelLocationInfoByAddress,
        {
          headers: { carrierToken },
          data: { address, enterpriseGuid },
        },
      );

      return getGeocodeFieldsFromAPIRes(
        address,
        mapObjectNullFieldsToEmptyStrings(data),
        useFormattedAddress,
        addressFieldName,
      );
    }

    if (isString(componentName)) {
      logToSystem(
        GC.LOG_MESSAGE_TYPE_GOOGLE_GEOCODE_API,
        { componentName, from: 'helpers geocodeByPlaceAddressWithTokenAndEnterpriceGuid' },
      );
    }

    const results = await geocodeByAddress(address);

    return getGoogleGeocodeByAddressDataFromResult(R.head(results), useFormattedAddress, addressFieldName);
  } catch (error) {
    callFunction(catchCallback);

    handleException(error, 'geocodeByPlaceAddressWithTokenAndEnterpriceGuid');
  }
};


export {
  getGeolocation,
  googleMapsGeocode,
  geocodeByPlaceAddress,
  makeGoogleAPILinkWithKey,
  getSpecificLocationValue,
  getGeocodeFieldsFromAPIRes,
  getDistanceBetweenTwoPoints,
  getGoogleGeocodeByAddressDataFromResult,
  geocodeByPlaceAddressWithTokenAndEnterpriseGuid,
};
