import commonLogic from './common/logic';
import rateLogic from './features/rate/logic';
import usersLogic from './features/user/logic';
import rolesLogic from './features/role/logic';
import fleetLogic from './features/fleet/logic';
import chartLogic from './features/charts/logic';
import newDOLogic from './features/new-do/logic';
import lbLogic from './features/load-board/logic';
import payrollLogic from './features/payroll/logic';
import carrierLogic from './features/carrier/logic';
import socketsLogic from './features/sockets/logic';
import importsLogic from './features/new-import/logic';
import fuelCardLogic from './features/fuel-cards/logic';
import reportLogic from './features/report-format/logic';
import configLogic from './features/configurations/logic';
import liteNewDOLogic from './features/lite-new-do/logic';
import invoiceCommonLogic from './features/invoice/logic';
import newDOQuoteLogic from './features/new-do-quote/logic';
import dispatchLogic from './features/dispatch-report/logic';
import driversCardLogic from './features/drivers-card/logic';
import tollChargesLogic from './features/toll-charges/logic';
import masterInvoceLogic from './features/master-invoice/logic';
import dispatchGroupLogic from './features/dispatch-group/logic';
import routingPermissionsLogic from './features/permission/logic';
import carrierInvoiceLogic from './features/invoice/carrier/logic';
import customerPortalLogic from './features/customer-portal/logic';
import dispatchPlannerLogic from './features/dispatch-planner/logic';
import ratingGeoFencingZoneLogic from './features/geo-fencing-zone/rating/logic';
import reportGeoFencingZoneLogic from './features/geo-fencing-zone/report/logic';
import dispatchPlannerEventsLogic from './features/dispatch-planner-events/logic';
////////////////////////////////////////////////

export default [
  ...lbLogic,
  ...rateLogic,
  ...chartLogic,
  ...rolesLogic,
  ...usersLogic,
  ...fleetLogic,
  ...newDOLogic,
  ...commonLogic,
  ...configLogic,
  ...reportLogic,
  ...payrollLogic,
  ...carrierLogic,
  ...socketsLogic,
  ...importsLogic,
  ...fuelCardLogic,
  ...dispatchLogic,
  ...liteNewDOLogic,
  ...newDOQuoteLogic,
  ...driversCardLogic,
  ...tollChargesLogic,
  ...masterInvoceLogic,
  ...invoiceCommonLogic,
  ...dispatchGroupLogic,
  ...carrierInvoiceLogic,
  ...customerPortalLogic,
  ...dispatchPlannerLogic,
  ...routingPermissionsLogic,
  ...ratingGeoFencingZoneLogic,
  ...reportGeoFencingZoneLogic,
  ...dispatchPlannerEventsLogic,
];
