import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export class SearchRefTypes extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { scopes: null, referenceTypes: {} };
    this.getScopesRequest = this.getScopesRequest.bind(this);
    this.getRefTypesRequest = this.getRefTypesRequest.bind(this);
  }

  async getScopesRequest() {
    const res = await sendRequest('get', endpointsMap.referenceTypeScopes);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newState = P.$set('scopes', data, this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncRefTypes -> getScopesRequest',
      );
    }
  }

  async getRefTypesRequest(branchGuid: string, scopeName: string) {
    let scopes = this.state.scopes;
    if (R.isNil(scopes)) {
      await this.getScopesRequest();
      scopes = this.state.scopes;
    }
    const scopeGuid = R.compose(
      R.prop('guid'),
      R.find(R.propEq(scopeName, 'name')),
    )(scopes);
    const params = { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.availableReferenceTypesForScope, { params } );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newState = P.$set(`referenceTypes.${scopeName}`, data, this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncRefTypes -> getRefTypesRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getRefTypesRequest)}</div>;
  }
}

export const withAsyncRefTypes = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <SearchRefTypes
          scopeName={this.props.scopeName}
          branchGuid={this.props.branchGuid}
          render={(parentState: Object, getRefTypesRequest: Function) =>
            <Component
              {...this.props}
              getRefTypesRequest={getRefTypesRequest}
              referenceTypes={parentState.referenceTypes} />
          } />
      );
    }
  };
};
