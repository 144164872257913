import * as R from 'ramda';
// features
import PC from '../features/permission/role-permission';
// constants
import * as GC from '../constants';
// helpers
import * as H from './helpers';
import { checkStringsContains } from './string';
import { getConfigValueFromStore } from './common';
//////////////////////////////////////////////////

// window
const getItemFromWindow = (itemName: string) => window[itemName]; // eslint-disable-line
const setWindowItemToNull = (itemName: string) => window[itemName] = null; // eslint-disable-line
const setItemToWindow = (itemName: string, itemData: Object) => window[itemName] = itemData; // eslint-disable-line

// branch
const getBranchFromWindowByGuid = (guid: string) => R.prop(guid, getItemFromWindow('amousBranches'));

const getBranchNameFromWindowByGuid = (guid: string) =>
  R.path([guid, GC.BRANCH_NAME], getItemFromWindow('amousBranches'));

// amousContainerTypeList
const getAmousContainerTypeListFromWindow = () => R.pathOr([], ['amousContainerTypeList'], window);

// amousConfig
const getAmousConfigFromWindow = () => R.propOr(null, 'amousConfig', window);

const setAmousConfigToWindow = ({ configs = {}, dropdowns = {} }: Object = {}) => {
  const amousConfigs = R.pathOr({}, ['amousConfig', 'configs'], window);
  const amousDropdowns = R.pathOr({}, ['amousConfig', 'dropdowns'], window);

  const amousConfig = {
    configs: R.mergeRight(amousConfigs, configs),
    dropdowns: R.mergeRight(dropdowns, amousDropdowns),
  };

  return setItemToWindow('amousConfig', amousConfig);
};

const getAmousConfigByNameFromWindow = (name: Object) => getConfigValueFromStore(
  name,
  getAmousConfigFromWindow(),
);

// HACK: to using without import G.
window.getAmousConfigByNameFromWindow = getAmousConfigByNameFromWindow;

const getHiddenMenuItemsConfigFromWindow = () => getConfigValueFromStore(
  GC.UI_HIDDEN_MENU_ITEMS,
  getAmousConfigFromWindow(),
);

const getHiddenTabsConfigFromWindow = () => getConfigValueFromStore(
  GC.UI_HIDDEN_TABS,
  getAmousConfigFromWindow(),
);

const getUseCommissionAssignmentConfigFromWindow = () => getConfigValueFromStore(
  GC.GENERAL_BRANCH_USE_COMMISSION_ASSIGNMENT,
  getAmousConfigFromWindow(),
);

const getShowChassisSectionConfigFromWindow = () => getConfigValueFromStore(
  GC.TEL_DRIVER_RATE_SHOW_RAILWAY_SECTION,
  getAmousConfigFromWindow(),
);

const getConfigDriverRateShowStartFinishPointFromWindow = () => getConfigValueFromStore(
  GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT,
  getAmousConfigFromWindow(),
);

const getConfigGeneralUomCalcDefaultUomSystemFromWindow = () => getConfigValueFromStore(
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  getAmousConfigFromWindow(),
);

const getSaferWatchCustomerKeyConfigFromWindow = () => getConfigValueFromStore(
  GC.INTEGRATION_SAFER_WATCH_CUSTOMER_KEY,
  getAmousConfigFromWindow(),
);

const getAutodialAppConfigFromWindow = () => getConfigValueFromStore(
  GC.UI_LOADBOARD_AUTODIALAPPNAME,
  getAmousConfigFromWindow(),
);

const getCalendarTimeIntervalConfigFromWindow = () => getConfigValueFromStore(
  GC.UI_CALENDAR_TIME_INTERVAL,
  getAmousConfigFromWindow(),
);

const getConfigUiOrderOrderEntryFromWindow = () => getConfigValueFromStore(
  GC.UI_ORDER_ORDER_ENTRY,
  getAmousConfigFromWindow(),
);

const getConfigUiReplaceDollarSymbolFromWindow = () => getConfigValueFromStore(
  GC.UI_REPLACE_DOLLAR_SYMBOL_WITH_US,
  getAmousConfigFromWindow(),
);

const showSaferWatch = () => H.isNotNilAndNotEmpty(getSaferWatchCustomerKeyConfigFromWindow());

const showAdvancePayment = () => R.pathOr(false, ['configs', 'showAdvancePayment'], getAmousConfigFromWindow());

const showTransportingCarrier = () =>
  R.path(['configs', 'showTransportingCarrier'], getAmousConfigFromWindow());

const getCarrierSynchronizationType = () =>
  R.path(['configs', 'carrierSynchronizationType'], getAmousConfigFromWindow());

const showCarrierSynchronization = () =>
  R.path(['configs', 'showCarrierSynchronization'], getAmousConfigFromWindow());

// amousCurrentUser
const getAmousCurrentUserFromWindow = () => R.propOr(null, 'amousCurrentUser', window);

const getAmousCurrentUserAuthoritiesFromWindow = () => R.pathOr([], ['amousCurrentUser', 'authorities'], window);

const hasAmousCurrentUserPermissions = (permissions: Array | string) => {
  if (H.isNilOrEmpty(permissions)) return false;

  const authorities = getAmousCurrentUserAuthoritiesFromWindow();

  const permissionsToUse = H.ifElse(
    H.isString(permissions),
    R.of(Array, permissions),
    permissions,
  );

  return R.or(R.includes(PC.ANY, permissions), checkStringsContains(permissionsToUse, authorities));
};

const hasNotAmousCurrentUserPermissions = (permissions: Array | string) => R.not(
  hasAmousCurrentUserPermissions(permissions),
);

const getHasNotEditRatePermissionsByRateType = (isCarrierRate: boolean) => {
  const editRatePermissions = H.ifElse(
    isCarrierRate,
    [PC.CARRIER_RATE_WRITE],
    [PC.FLEET_RATE_WRITE],
  );

  return hasNotAmousCurrentUserPermissions(editRatePermissions);
};

const getAccessForAmousCurrentUserByPermissions = (has: any, notHas: any) => {
  let allow = true;

  if (R.and(H.isNotNil(has), H.isNotNil(notHas))) {
    allow = R.and(
      hasAmousCurrentUserPermissions(has),
      hasNotAmousCurrentUserPermissions(notHas),
    );
  } else if (H.isNotNil(has)) {
    allow = hasAmousCurrentUserPermissions(has);
  } else if (H.isNotNil(notHas)) {
    allow = hasNotAmousCurrentUserPermissions(notHas);
  }

  return allow;
};

const getAmousCurrentUserGuidFromWindow = () => R.path(
  ['user_guid'],
  getAmousCurrentUserFromWindow(),
);

const getAmousUserTypeFromWindow = () => R.propOr(null, 'user_type', getAmousCurrentUserFromWindow());

const getAmousCurrentUserBranchGuidFromWindow = () => R.propOr(
  null,
  'enterprise_guid',
  getAmousCurrentUserFromWindow(),
);

const getAmousCurrentUserLoginIdFromWindow = () => R.propOr(
  null,
  'login_id',
  getAmousCurrentUserFromWindow(),
);

const isCurrentUserSuperAdmin = ({ authorities }: Object) => R.includes(
  PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
  authorities,
);

const hasCurrentUserPermission = (permission: string, { authorities }: Object) => R.includes(
  permission,
  authorities,
);

const isCurrentUserTypeCarrier = () => R.equals(
  getAmousUserTypeFromWindow(),
  GC.USER_ROLE_TYPE_CARRIER,
);

const isCurrentUserTypeCustomer = () => R.equals(
  getAmousUserTypeFromWindow(),
  GC.USER_ROLE_TYPE_CUSTOMER,
);

const getIsAmousUserSuperAdminFromWindow = () => isCurrentUserSuperAdmin(getAmousCurrentUserFromWindow());


const getHasAmousCurrentUserPermissionFromWindow = (permissionName: string) => hasCurrentUserPermission(
  permissionName,
  getAmousCurrentUserFromWindow(),
);

// amousCurrentBranch
const getAmousCurrentBranchFromWindow = () => R.propOr(null, 'amousCurrentBranch', window);

const getAmousCurrentBranchGuidFromWindow = () => R.propOr(
  null,
  GC.FIELD_GUID,
  getAmousCurrentBranchFromWindow(),
);

const isCurrentBranchTypeCustomer = () => R.pathEq(
  GC.BRANCH_TYPE_ENUM_CUSTOMER,
  [GC.BRANCH_TYPE],
  getAmousCurrentBranchFromWindow(),
);

// amousBranchConfigs
const setAmousBranchConfigsToWindow = (branchGuid: string, data: Object) => {
  if (R.isNil(window.amousBranchConfigs)) window.amousBranchConfigs = {};

  if (R.isNil(window.amousBranchConfigs[branchGuid])) window.amousBranchConfigs[branchGuid] = {};

  const { mappedConfigs, receivedConfigs } = data;
  const { configs, dropdowns } = mappedConfigs;

  const branchConfigs = R.pathOr({}, ['amousBranchConfigs', branchGuid, 'mappedConfigs', 'configs'], window);
  const branchDropdowns = R.pathOr({}, ['amousBranchConfigs', branchGuid, 'mappedConfigs', 'dropdowns'], window);
  const branchReceivedConfigs = R.pathOr([], ['amousBranchConfigs', branchGuid, 'receivedConfigs'], window);

  const dataToUse = {
    receivedConfigs: R.uniq(R.concat(receivedConfigs, branchReceivedConfigs)),

    mappedConfigs: {
      configs: R.mergeRight(branchConfigs, configs),
      dropdowns: R.mergeRight(branchDropdowns, dropdowns),
    },
  };

  window.amousBranchConfigs[branchGuid] = dataToUse;
};

const getAmousBranchConfigsFromWindow = (branchGuid: string) => R.path(
  ['amousBranchConfigs', branchGuid],
  window,
);

const getAmousBranchConfigFromWindow = (configName: string, branchGuid: string) => {
  const branchGuidToUse = R.or(branchGuid, getAmousCurrentBranchGuidFromWindow());
  const branchConfigs = getAmousBranchConfigsFromWindow(branchGuidToUse);
  const mappedConfigs = R.path(['mappedConfigs'], branchConfigs);

  return getConfigValueFromStore(configName, mappedConfigs);
};

const getAmousBranchReceivedConfigsFromWindow = (branchGuid: string) => {
  const branchGuidToUse = R.or(branchGuid, getAmousCurrentBranchGuidFromWindow());
  const branchConfigs = getAmousBranchConfigsFromWindow(branchGuidToUse);
  const receivedConfigs = R.pathOr([], ['receivedConfigs'], branchConfigs);

  return receivedConfigs;
};

const clearWindowAmousBranchConfigsByInterval = () => setInterval(() => {
  window.amousBranchConfigs = null;
}, 1800000);

// amousLoadDivisionMap
const initWindowAmousLoadDivisionMap = () => window.amousLoadDivisionMap = new Map();
const hasWindowAmousLoadDivision = (loadGuid: string) => window.amousLoadDivisionMap.has(loadGuid);
const getWindowAmousLoadDivision = (loadGuid: string) => window.amousLoadDivisionMap.get(loadGuid);
const deleteWindowAmousLoadDivision = (loadGuid: string) => window.amousLoadDivisionMap.delete(loadGuid);
const setWindowAmousLoadDivision = (
  loadGuid: string, divisionGuid: string,
) => window.amousLoadDivisionMap.set(loadGuid, divisionGuid);

export {
  showSaferWatch,
  setItemToWindow,
  getItemFromWindow,
  showAdvancePayment,
  setWindowItemToNull,
  setAmousConfigToWindow,
  isCurrentUserSuperAdmin,
  showTransportingCarrier,
  isCurrentUserTypeCarrier,
  getAmousConfigFromWindow,
  hasCurrentUserPermission,
  isCurrentUserTypeCustomer,
  getBranchFromWindowByGuid,
  showCarrierSynchronization,
  getAmousUserTypeFromWindow,
  isCurrentBranchTypeCustomer,
  getCarrierSynchronizationType,
  getBranchNameFromWindowByGuid,
  getAmousCurrentUserFromWindow,
  setAmousBranchConfigsToWindow,
  getHiddenTabsConfigFromWindow,
  getAmousBranchConfigFromWindow,
  hasAmousCurrentUserPermissions,
  getAutodialAppConfigFromWindow,
  getAmousConfigByNameFromWindow,
  getAmousBranchConfigsFromWindow,
  getAmousCurrentBranchFromWindow,
  hasNotAmousCurrentUserPermissions,
  getAmousCurrentUserGuidFromWindow,
  getIsAmousUserSuperAdminFromWindow,
  getHiddenMenuItemsConfigFromWindow,
  getAmousCurrentBranchGuidFromWindow,
  getAmousContainerTypeListFromWindow,
  getAmousCurrentUserLoginIdFromWindow,
  getConfigUiOrderOrderEntryFromWindow,
  getShowChassisSectionConfigFromWindow,
  getHasNotEditRatePermissionsByRateType,
  getAmousBranchReceivedConfigsFromWindow,
  clearWindowAmousBranchConfigsByInterval,
  getAmousCurrentUserBranchGuidFromWindow,
  getCalendarTimeIntervalConfigFromWindow,
  getAmousCurrentUserAuthoritiesFromWindow,
  getSaferWatchCustomerKeyConfigFromWindow,
  getConfigUiReplaceDollarSymbolFromWindow,
  getAccessForAmousCurrentUserByPermissions,
  getHasAmousCurrentUserPermissionFromWindow,
  getUseCommissionAssignmentConfigFromWindow,
  getConfigDriverRateShowStartFinishPointFromWindow,
  getConfigGeneralUomCalcDefaultUomSystemFromWindow,
  // amousLoadDivisionMap
  setWindowAmousLoadDivision,
  getWindowAmousLoadDivision,
  hasWindowAmousLoadDivision,
  deleteWindowAmousLoadDivision,
  initWindowAmousLoadDivisionMap,
};
