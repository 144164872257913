import * as R from 'ramda';
//////////////////////////////////////////////////

// NOTE: add all common functional helpers here

const notEquals = R.complement(R.equals);
const notPropEq = R.complement(R.propEq);
const isNaN = R.equals(NaN);
const isTrue = R.equals(true);
const isNotNaN = notEquals(NaN);
const isNot = R.complement(R.is);
const notHas = R.complement(R.has);
const mapIndexed = R.addIndex(R.map);
const isNotNil = R.complement(R.isNil);
const isNotEmpty = R.complement(R.isEmpty);
const filterIndexed = R.addIndex(R.filter);
const notContain = R.complement(R.includes);
const isZero = (value: any) => R.equals(value, 0);
const isNotZero = (value: any) => notEquals(value, 0);
const isFalse = (value: any) => R.equals(value, false);
const isAllTrue = (...arg: Array) => R.all(isTrue, arg);
const isAnyTrue = (...arg: Array) => R.any(isTrue, arg);
const isAllFalse = (...arg: Array) => R.all(isFalse, arg);
const isAnyFalse = (...arg: Array) => R.any(isFalse, arg);
const isNilOrZero = (value: any) => R.or(R.isNil(value), isZero(value));
const isNilOrFalse = (value: any) => R.or(R.isNil(value), isFalse(value));
const isNilOrEmpty = (value: any) => R.or(R.isNil(value), R.isEmpty(value));
const isEmptyOrFalse = (value: any) => R.or(R.isEmpty(value), isFalse(value));
const isNotNilAndNotZero = (value: any) => R.and(isNotNil(value), isNotZero(value));
const isNotNilAndNotEmpty = (value: any) => R.and(isNotNil(value), isNotEmpty(value));

const isPathNotNilAndNotEmpty = R.curry((path: Array, value: any) => R.and(
  isNotNil(R.path(path, value)),
  isNotEmpty(R.path(path, value)),
));

const isAllNilOrEmpty = R.all(isNilOrEmpty);
const isAnyNilOrEmpty = R.any(isNilOrEmpty);
const isAllNotNilOrNotEmpty = R.all(isNotNilAndNotEmpty);
const isOneNotNilOrNotEmpty = R.any(isNotNilAndNotEmpty);

const isArray = R.is(Array);
const isObject = R.is(Object);
const isString = R.is(String);
const isNumber = R.is(Number);
const isBoolean = R.is(Boolean);
const isJestMockFn = (fn: any) => isTrue(R.path(['_isMockFunction'], fn));
const isFunction = (fn: any) => R.or(R.is(Function, fn), isJestMockFn(fn));

// falsy - null, undefined, 0, '', NaN, false
const isFalsy = (value: any) => isFalse(Boolean(value));

const isFirstItemArray = R.pathSatisfies(R.is(Array), [0]);
const isFirstItemString = R.pathSatisfies(R.is(String), [0]);
const isFirstItemObject = R.pathSatisfies(R.is(Object), [0]);

const isArrayOfStrings = R.allPass([isArray, isNotEmpty, R.all(isString)]);

const sortByIndex = R.sortBy(R.prop('index'));
const sortByProp = (propName: string) => R.sortBy(R.prop(propName));
const indexByGuid = (items: Array) => R.indexBy(R.prop('guid'), items);
const indexByValue = (items: Array) => R.indexBy(R.prop('value'), items);

const callFunction = (func: any) => {
  if (isJestMockFn(func)) return func();

  if (isFunction(func)) func();
};

const callFunctionWithArgs = (func: any, args: Object) => {
  if (isFunction(func)) func(args);
};

const callFunctionWith2Args = (func: any, args1: Object, args2: Object) => {
  if (isFunction(func)) func(args1, args2);
};

const callFunctionTwice = (func: any) => {
  callFunction(func);
  callFunction(func);
};

const getOrElse = (obj: Object, prop: string, elseSt: any) => {
  if ((isNotNil(obj) && isNotNil(obj[prop]))) return obj[prop];

  return elseSt;
};

const ifElse = (predicate: any, ifSt: any, elseSt: any) => {
  if (predicate) return ifSt;

  return elseSt;
};

const assign = (...args: Object) => Object.assign({}, ...args);

export {
  isNot,
  isNaN,
  assign,
  isTrue,
  notHas,
  isZero,
  ifElse,
  isFalsy,
  isFalse,
  isArray,
  isNotNaN,
  isNotNil,
  isObject,
  isString,
  isNumber,
  getOrElse,
  notEquals,
  isNotZero,
  isAllTrue,
  isAnyTrue,
  isBoolean,
  notPropEq,
  mapIndexed,
  notContain,
  isAllFalse,
  isFunction,
  sortByProp,
  isAnyFalse,
  isNotEmpty,
  sortByIndex,
  isNilOrZero,
  indexByGuid,
  indexByValue,
  isJestMockFn,
  isNilOrEmpty,
  callFunction,
  isNilOrFalse,
  filterIndexed,
  isEmptyOrFalse,
  isAnyNilOrEmpty,
  isAllNilOrEmpty,
  isArrayOfStrings,
  isFirstItemArray,
  isFirstItemString,
  isFirstItemObject,
  callFunctionTwice,
  isNotNilAndNotZero,
  isNotNilAndNotEmpty,
  callFunctionWithArgs,
  isAllNotNilOrNotEmpty,
  isOneNotNilOrNotEmpty,
  callFunctionWith2Args,
  isPathNotNilAndNotEmpty,
};
