import * as R from 'ramda';
import React, { Component } from 'react';
import { OuterClick } from 'react-outer-click';
import ReactDOM, { createPortal } from 'react-dom';
// components
import { Wrapper } from '../components/actions-dropdown/ui';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const initialState = {
  coords: null,
  opened: false,
  popupComponent: null,
};

export class PopupContent extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      coords: {
        top: 0,
        right: 0,
        margin: 0,
      },
    };
  }

  componentDidMount() {
    const { el, type, minusTop, minusRight } = this.props;
    const params = ReactDOM.findDOMNode(el).getBoundingClientRect();
    const content = ReactDOM.findDOMNode(this).getBoundingClientRect();
    this.setPosition({ params, content, type, minusTop, minusRight });
  }

  setPosition({ params, content, type, minusTop, minusRight }: Object) {
    const positionRight = {
      left: params.left,
      right: window.innerWidth - params.right - R.of(Array, minusRight, 0),
      center: window.innerWidth - params.left - (params.width / 2) - (content.width / 2),
    };
    let position = {
      margin: '10px 0 0 0',
      right: positionRight[type],
      top: R.subtract(params.bottom, R.or(minusTop, 10)),
    };
    const expandedContainer = document.getElementsByClassName('react-draggable')[0];
    if (R.and(G.isNotNilAndNotEmpty(expandedContainer), R.equals(type, 'left'))) {
      const windowWidth = window.innerWidth;
      const expandedContainerWidth = expandedContainer.clientWidth;
      const margin = R.subtract(windowWidth, expandedContainerWidth);
      const right = R.subtract(params.left, margin);

      position = R.assoc('right', right, position);
    }
    if (R.lt(R.subtract(window.innerHeight, R.add(params.bottom, content.height)), 60)) {
      const top = params.top - 10 - content.height;
      const openTopParams = {
        margin: '0 0 10px 0',
        top: G.ifElse(R.lt(top, 0), 0, top),
      };
      position = R.mergeRight(position, openTopParams);
    }
    this.setState({ coords: position });
  }

  render() {
    const { type, children, wrapperPadding, zIndex } = this.props;
    return (
      <Wrapper
        zIndex='2'
        type={type}
        zIndex={zIndex}
        p={wrapperPadding}
        id='fixed-popover-wrapper'
        positions={this.state.coords}
        {...R.pathOr({}, ['boxStyles'], this.props)}
      >
        {children}
      </Wrapper>
    );
  }
}

export const withFixedPopover = (
  ComposedComponent: any,
  withoutScrollHandler: boolean = false,
  usePortal: boolean = false,
) => {
  class fixedPopover extends Component {
    constructor() {
      super();
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.addScrollHandler = this.addScrollHandler.bind(this);
      this.removeScrollHandler = this.removeScrollHandler.bind(this);
      this.state = initialState;
    }

    addScrollHandler() {
      window.addEventListener('scroll', this.handleClose, true);
    }

    removeScrollHandler() {
      window.removeEventListener('scroll', this.handleClose, true);
    }

    handleOpen(props: Object) {
      const {
        el,
        content,
        zIndex,
        position,
        minusTop,
        boxStyles,
        minusRight,
        wrapperPadding,
        shouldNotCloseOnScroll,
      } = props;
      if (shouldNotCloseOnScroll) {
        this.removeScrollHandler();
      } else if (R.not(withoutScrollHandler)) {
        this.addScrollHandler();
      }
      this.setState({
        opened: true,
        popupComponent: (
          <OuterClick onOuterClick={this.handleClose}>
            <PopupContent
              el={el}
              type={position}
              zIndex={zIndex}
              minusTop={minusTop}
              boxStyles={boxStyles}
              minusRight={minusRight}
              wrapperPadding={wrapperPadding}
            >
              {content}
            </PopupContent>
          </OuterClick>
        ),
      });
    }

    handleClose(e: Object) {
      if (R.pathEq('fixed-popover-wrapper', ['target', 'parentNode', 'id'], e)) return;
      this.setState(initialState);
      this.removeScrollHandler();
    }

    render() {
      const { props, handleOpen, handleClose, state: { opened, popupComponent } } = this;

      const { popoverOverflow } = props;

      return (
        <div style={{ overflow: R.or(popoverOverflow, 'unset') }}>
          <ComposedComponent {...props} openFixedPopup={handleOpen} closeFixedPopup={handleClose} />
          {
            opened &&
            usePortal ? createPortal(popupComponent, document.body) : popupComponent
          }
        </div>
      );
    }

  }

  return fixedPopover;
};
