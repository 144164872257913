import * as R from 'ramda';
import validator from 'validator';
// constants
import * as GC from '../constants';
import { ENUMS } from '../constants/enums';
// helpers
import { getValueFromObject } from './getter';
import { createStringFromArray } from './string';
import { isItemTypeVehicle } from './business-logic';
import {
  getAmousConfigByNameFromWindow,
  getConfigGeneralUomCalcDefaultUomSystemFromWindow,
} from './window';
import {
  isTrue,
  ifElse,
  isZero,
  notEquals,
  isNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const isOdd = (num: number) => num % 2;

const curriedParseInt = R.curry((text: string) => parseInt(text, 10));

const NaNToZero = R.curry((value: any) => {
  if (isNaN(value)) return 0;

  return value;
});

const NaNToNull = R.curry((value: any) => {
  if (isNaN(value)) return null;

  return value;
});

const fromNillOrEmptyToZero = (item: any) => ifElse(isNilOrEmpty(item), 0, item);

const setPercentage = (percent: number, from: number) => (
  (percent / 100) * from
);

const toNumber = (num: string) => Number(num);

// NOTE: for rounding use mathRoundNumber, check using instead of toFixed in all system
const toFixedFromString = (val: any, qty: number = 2) => {
  const stringValue = String(val);

  if (validator.isInt(stringValue)) return val;

  const floatValue = validator.toFloat(stringValue);

  return floatValue.toFixed(R.or(qty, 1));
};

// NOTE: for rounding use mathRoundNumber
const toFixed = (value: number, digits: number = 2) => {
  if (R.is(Number, value)) return value.toFixed(digits);

  if (R.is(String, value)) return toFixedFromString(value, digits);

  return value;
};

const calculateMarginPercent = (inp: number, out: number) => {
  const sub = R.subtract(inp, out);
  const div = R.or(out, 1);

  return toFixed(R.multiply(R.divide(sub, div), 100));
};

// TODO: check handling undefined and NaN
const mathRoundNumber = (val: number, digits: number = 2) => {
  const multiplier = 10 ** digits;

  return Math.round(val * multiplier) / multiplier;
};

const fromKgsToPounds = (val: number, digits: number = 4) => {
  const multiplier = 10 ** digits;

  return Math.round(val * 2.20462262185 * multiplier) / multiplier;
};

const fromPoundsToKgs = (val: number, digits: number = 4) => {
  const multiplier = 10 ** digits;

  return Math.round((val / 2.20462262185) * multiplier) / multiplier;
};

const fromKgsToMetricTons = (val: number, digits: number = 4) => (
  (val / 1000).toFixed(digits)
);

const fromMetricTonsToKgs = (val: number, digits: number = 4) => (
  (val * 1000).toFixed(digits)
);

const fromKgsToShortTons = (val: number, digits: number = 4) => (
  (val / 907.18474).toFixed(digits)
);

const fromShortTonsToKgs = (val: number, digits: number = 4) => (
  (val * 907.18474).toFixed(digits)
);

const poundsKgsFromTo = (to: string, val: number) => {
  if (to === GC.UOM_KILOGRAM) {
    return fromPoundsToKgs(val);
  } else if (to === GC.UOM_POUND) {
    return fromKgsToPounds(val);
  }
};

const getWeightWithUomByConfigUomSystem = (storedWeight: number) => {
  const uomSystem = getConfigGeneralUomCalcDefaultUomSystemFromWindow();

  if (R.equals(uomSystem, GC.METRIC_SYSTEM)) return `${storedWeight} ${GC.UOM_KILOGRAM}`;

  return `${fromKgsToPounds(storedWeight, 2)} ${GC.UOM_POUND}`;
};

const fromMilesToKms = (val: number, toFixedNumber: number) => (
  (val * 1.6093).toFixed(R.or(toFixedNumber, 4))
);

const fromKmsToMiles = (val: number, toFixedNumber: number) => (
  (val / 1.6093).toFixed(R.or(toFixedNumber, 4))
);

const fromKmsToMilesNumber = (val: number, toFixedNumber: number) => (
  toNumber(fromKmsToMiles(val, toFixedNumber))
);

const fromMilesToKmsNumber = (val: number, toFixedNumber: number) => (
  toNumber(fromMilesToKms(val, toFixedNumber))
);

const convertDistanceAccordingUom = (val: number, uom: string) => ifElse(
  R.equals(uom, GC.UOM_KILOMETER),
  fromMilesToKms,
  fromKmsToMiles,
)(val);

const convertDistanceAccordingUomSystem = (val: number, system: string) => {
  if (R.or(R.isNil(system), R.equals(system, GC.METRIC_SYSTEM))) return `${val} ${GC.UOM_KILOMETER}`;

  return `${fromKmsToMiles(val, 2)} ${GC.UOM_MILE}`;
};

const milesKmsFromTo = (to: string, val: number, toFixedNumber: number) => {
  if (to === GC.UOM_KILOMETER) {
    return fromMilesToKms(val, toFixedNumber);
  } else if (to === GC.UOM_MILE) {
    return fromKmsToMiles(val, toFixedNumber);
  }
};

const milesKmsFromToAccordingSystemWithNullable = (
  toUom: string,
  fromUom: string,
  value: any,
  toFixedNumber: number,
) => {
  if (isNilOrEmpty(value)) return 0;

  if (R.equals(toUom, fromUom)) return value;

  if (R.equals(toUom, GC.UOM_KILOMETER)) {
    return fromMilesToKms(value, toFixedNumber);
  } else if (R.equals(toUom, GC.UOM_MILE)) {
    return fromKmsToMiles(value, toFixedNumber);
  }

  return fromKmsToMiles(value, toFixedNumber);
};

const fromCubicFeetToMeter = (val: number, toFixedNumber: number) => (
  (val / 35.3147).toFixed(R.or(toFixedNumber, 4))
);

const fromCubicMeterToFeet = (val: number, toFixedNumber: number) => (
  (val * 35.3147).toFixed(R.or(toFixedNumber, 4))
);

const cubicFeetMeterFromTo = (to: string, val: number) => {
  if (to === GC.UOM_CUBIC_METERS) {
    return fromCubicFeetToMeter(val);
  } else if (to === GC.UOM_CUBIC_FEET) {
    return fromCubicMeterToFeet(val);
  }
};

const fromCelsiusToFahrenheit = (val: number, toFixedNumber: number = 4) => (
  ((val * 1.8) + 32).toFixed(toFixedNumber)
);

const weightToKgs = R.cond([
  [
    R.propEq(GC.UOM_KILOGRAM, GC.FIELD_FROM),
    (opt: Object) => getValueFromObject(opt),
  ],
  [
    R.propEq(GC.UOM_POUND, GC.FIELD_FROM),
    (opt: Object) => fromPoundsToKgs(getValueFromObject(opt)),
  ],
  [
    R.propEq(GC.UOM_METRIC_TON, GC.FIELD_FROM),
    (opt: Object) => fromMetricTonsToKgs(getValueFromObject(opt)),
  ],
  [
    R.propEq(GC.UOM_TON_US, GC.FIELD_FROM),
    (opt: Object) => fromShortTonsToKgs(getValueFromObject(opt)),
  ],
  [R.T, R.always(0)],
]);

const kgsToWeight = R.cond([
  [
    R.propEq(GC.UOM_KILOGRAM, GC.FIELD_TO),
    (opt: Object) => getValueFromObject(opt),
  ],
  [
    R.propEq(GC.UOM_POUND, GC.FIELD_TO),
    (opt: Object) => fromKgsToPounds(getValueFromObject(opt)),
  ],
  [
    R.propEq(GC.UOM_METRIC_TON, GC.FIELD_TO),
    (opt: Object) => fromKgsToMetricTons(getValueFromObject(opt)),
  ],
  [
    R.propEq(GC.UOM_TON_US, GC.FIELD_TO),
    (opt: Object) => fromKgsToShortTons(getValueFromObject(opt)),
  ],
  [R.T, R.always(0)],
]);

const calcWeightFromTo = (options: Object) => {
  const { to, from, value, toFixedNumber } = options;

  const fromValue = R.or(from, GC.UOM_KILOGRAM);
  const toValue = R.or(to, GC.UOM_KILOGRAM);
  const valueToConvert = R.or(value, 0);
  const kgs = weightToKgs({ from: fromValue, value: valueToConvert });

  return toFixed(kgsToWeight({ value: kgs, to: toValue }), R.or(toFixedNumber, 4));
};

const weight100Map = {
  [GC.UOM_POUND_100]: GC.UOM_POUND,
  [GC.UOM_KILOGRAM_100]: GC.UOM_KILOGRAM,
};

const calcWeightFromTo100 = (options: Object) => {
  const { to, from, value, toFixedNumber } = options;

  const fromValue = R.or(from, GC.UOM_KILOGRAM);
  const toValue = R.pathOr(GC.UOM_KILOGRAM, [to], weight100Map);
  const valueToConvert = R.or(value, 0);
  const kgs = weightToKgs({ from: fromValue, value: valueToConvert });
  const weight = kgsToWeight({ value: kgs, to: toValue });

  if (isNotNilAndNotEmpty(weight)) {
    return toFixed(R.divide(weight, 100), R.or(toFixedNumber, 4));
  }

  return weight;
};

const calcItemsTotalWeightWithoutQty = (items: Array) => {
  const uomSystem = getAmousConfigByNameFromWindow(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM);

  const weightType = ifElse(
    R.equals(uomSystem, GC.METRIC_SYSTEM),
    GC.UOM_KILOGRAM,
    GC.UOM_POUND,
  );

  const weight = R.reduce(
    (accum: Object, item: Object) => {
      const itemWeight = R.path([GC.FIELD_ITEM_WEIGHT], item);

      if (R.isNil(itemWeight)) return accum;

      const itemWeightType = R.pathOr(GC.UOM_KILOGRAM, [GC.FIELD_ITEM_WEIGHT_TYPE], item);

      if (R.equals(itemWeightType, weightType)) return R.add(itemWeight, accum);

      return R.add(accum, calcWeightFromTo({
        to: weightType,
        toFixedNumber: 2,
        value: itemWeight,
        from: itemWeightType,
      }));
    },
    0,
    items,
  );

  return {
    [GC.FIELD_ITEM_WEIGHT_TYPE]: weightType,
    [GC.FIELD_ITEM_WEIGHT]: ifElse(isZero(weight), 0, weight.toFixed(2)),
  };
};

const getItemsTotalWeightInfo = (items: Array) => {
  const { weight, weightType } = calcItemsTotalWeightWithoutQty(items);

  if (isNilOrEmpty(weight)) return null;

  return `${weight} ${weightType}`;
};

const calculateTotalQuantity = R.reduce((accum: Object, item: Object): Object => {
  let itemQuantity = R.pathOr(0, [GC.FIELD_ITEM_QUANTITY], item);

  const isVehicle = isItemTypeVehicle(item);

  let itemPackageType = R.pathOr(
    GC.ITEM_QUANTITY_UNIT_PIECES,
    [GC.FIELD_ITEM_PACKAGE_TYPE],
    item,
  );

  if (isVehicle) {
    itemQuantity = 1;
    itemPackageType = GC.ITEM_TYPE_VEHICLE;
  }

  if (R.isEmpty(accum)) {
    return {
      [GC.FIELD_ITEM_QUANTITY]: itemQuantity,
      [GC.FIELD_ITEM_PACKAGE_TYPE]: itemPackageType,
    };
  }

  if (R.isNil(itemQuantity)) {
    return accum;
  }

  let accPackageType = R.pathOr(
    GC.ITEM_QUANTITY_UNIT_PIECES,
    [GC.FIELD_ITEM_PACKAGE_TYPE],
    accum,
  );
  const accItemQuantity = R.prop(GC.FIELD_ITEM_QUANTITY, accum);

  if (notEquals(itemPackageType, accPackageType)) {
    accPackageType = GC.ITEM_QUANTITY_UNIT_PIECES;
  }

  const resultQuantity = R.add(accItemQuantity, itemQuantity);

  return {
    [GC.FIELD_ITEM_PACKAGE_TYPE]: accPackageType,
    [GC.FIELD_ITEM_QUANTITY]: ifElse(
      R.equals(accPackageType, GC.ITEM_TYPE_VEHICLE),
      resultQuantity,
      resultQuantity.toFixed(1),
    ),
  };
}, {});

const makeTotalPickupQuantityObjectFromItems = (items: Array) => {
  if (isNilOrEmpty(items)) {
    return {
      [GC.FIELD_ITEM_PACKAGE_TYPE]: null,
      [GC.FIELD_TOTAL_PICKUP_QUANTITY]: null,
    };
  }

  const totalPickupQuantity = R.compose(
    R.ifElse(isZero, R.always(null), mathRoundNumber),
    NaNToZero,
    R.sum,
    R.map(R.propOr(null, GC.FIELD_QUANTITY)),
  )(items);

  const packageType = R.compose(
    R.ifElse(
      R.pathEq(1, ['length']),
      R.head,
      R.always(GC.ITEM_QUANTITY_UNIT_PIECES),
    ),
    R.uniq,
    R.map(R.propOr(null, GC.FIELD_PACKAGE_TYPE)),
  )(items);

  return { packageType, totalPickupQuantity };
};

const getItemsTotalQuantityInfo = (items: Array) => {
  const { quantity, packageType } = calculateTotalQuantity(items);

  if (isNilOrEmpty(quantity)) return null;

  return `${quantity} ${packageType}`;
};

const calculateTotalVolumeWithoutQty = R.reduce((accum: Object, item: Object): Object => {
  const itemVolume = R.path([GC.FIELD_ITEM_VOLUME], item);
  const itemVolumeUom = R.path([GC.FIELD_ITEM_VOLUME_UOM], item);

  if (R.isNil(itemVolume)) {
    return {
      [GC.ITEMS_VOLUME]: '',
      [GC.ITEMS_VOLUME_UOM]: itemVolumeUom,
    };
  }

  if (R.isEmpty(accum)) {
    return {
      [GC.ITEMS_VOLUME_UOM]: itemVolumeUom,
      [GC.ITEMS_VOLUME]: R.or(itemVolume, 0),
    };
  }

  if (R.isNil(itemVolume)) {
    return accum;
  }

  const accVolumeUom = ifElse(
    notEquals(itemVolumeUom, R.path([GC.ITEMS_VOLUME_UOM], accum)),
    '',
    R.pathOr('', [GC.ITEMS_VOLUME_UOM], accum),
  );
  const accVolumeValue = R.prop(GC.ITEMS_VOLUME, accum);
  const resultValue = R.add(accVolumeValue, itemVolume);

  return {
    [GC.ITEMS_VOLUME_UOM]: accVolumeUom,
    [GC.ITEMS_VOLUME]: resultValue.toFixed(2),
  };
}, {});

const getItemsTotalVolumeInfo = (items: Array) => {
  const { itemsVolume, itemsVolumeUom } = calculateTotalVolumeWithoutQty(items);

  if (isNilOrEmpty(itemsVolume)) return null;

  return `${itemsVolume} ${itemsVolumeUom}`;
};

const calculateTotalDistance = (distances: Array) => {
  const uomSystem = getAmousConfigByNameFromWindow(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM);
  const distanceUom = ifElse(
    R.equals(uomSystem, GC.METRIC_SYSTEM),
    GC.UOM_KILOMETER,
    GC.UOM_MILE,
  );
  const totalDistance = R.reduce(
    (accum: number, distance: Object) => {
      let itemDistance;
      let itemDistanceUom;
      const { manualDistance, manualDistanceUom, systemDistance, systemDistanceUom } = distance;

      if (isNotNilAndNotEmpty(manualDistance)) {
        itemDistance = manualDistance;
        itemDistanceUom = R.or(manualDistanceUom, GC.UOM_MILE);
      } else if (isNotNilAndNotEmpty(systemDistance)) {
        itemDistance = systemDistance;
        itemDistanceUom = R.or(systemDistanceUom, GC.UOM_MILE);
      }

      if (R.isNil(itemDistance)) {
        return accum;
      }

      if (notEquals(itemDistanceUom, distanceUom)) {
        itemDistance = milesKmsFromTo(distanceUom, itemDistance, 2);
      }

      return R.add(itemDistance, accum);
    },
    0,
    distances,
  );

  return {
    [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: distanceUom,
    [GC.FIELD_TOTAL_TRIP_DISTANCE]: toFixed(totalDistance, 2),
  };
};

const getLoadTotalDistance = (load: Object, distName: string) => {
  const events = R.pathOr([], [GC.FIELD_LOAD_STOPS], load);

  const distances = R.compose(
    R.filter((item: Object) => isNotNilAndNotEmpty(item)),
    R.map((item: Object) => R.prop(distName, item)),
  )(R.values(events));

  return calculateTotalDistance(distances);
};

const getStopItemsInfo = (items: Array) => {
  const weightInfo = getItemsTotalWeightInfo(items);
  const quantityInfo = getItemsTotalQuantityInfo(items);
  const volumeInfo = getItemsTotalVolumeInfo(items);

  return createStringFromArray([weightInfo, quantityInfo, volumeInfo], ', ');
};

const getItemsTotals = (items: Array) => ({
  itemsTotal: items.length,
  totalQuantity: getItemsTotalQuantityInfo(items),
  totalWeight: calcItemsTotalWeightWithoutQty(items),
  anyHazardous: R.any(R.prop(GC.FIELD_ITEM_HAZARDOUS), items),
  hasTemperatureSensor: R.any(
    ({ temperatureSensor }: Object) => R.equals(temperatureSensor, ENUMS.ENUM_YES),
    items,
  ),
  temperatureLow: R.compose(
    R.any(isTrue),
    R.map((item: Object) => {
      const temp = R.prop(GC.FIELD_ITEM_TEMPERATURE_LOW, item);

      if (isNilOrEmpty(temp)) return false;

      return R.lte(
        R.prop(GC.FIELD_ITEM_TEMPERATURE_LOW, item),
        ifElse(R.equals(GC.UOM_FAHRENHEIT, R.prop(GC.FIELD_ITEM_TEMPERATURE_UOM, item)), 32, 0),
      );
    }),
  )(items),
  temperatureHigh: R.compose(
    R.any(isTrue),
    R.map((item: Object) => {
      const temp = R.prop(GC.FIELD_ITEM_TEMPERATURE_HIGH, item);

      if (isNilOrEmpty(temp)) return false;

      return R.gt(
        R.prop(GC.FIELD_ITEM_TEMPERATURE_HIGH, item),
        ifElse(R.equals(GC.UOM_FAHRENHEIT, R.prop(GC.FIELD_ITEM_TEMPERATURE_UOM, item)), 32, 0),
      );
    }),
  )(items),
});

const calcContainersTotalWeightWithoutQty = R.reduce((accum: Object, item: Object): Object => {
  let itemWeight = R.pathOr(0, [GC.FIELD_FULL_CONTAINER_WEIGHT], item);

  const itemWeightUom = R.pathOr(GC.UOM_KILOGRAM, [GC.FIELD_WEIGHT_UOM], item);

  if (R.isEmpty(accum)) {
    return {
      [GC.FIELD_WEIGHT_UOM]: itemWeightUom,
      [GC.FIELD_FULL_CONTAINER_WEIGHT]: itemWeight,
    };
  }

  if (R.isNil(itemWeight)) {
    return accum;
  }

  const accWeightUom = R.pathOr(GC.UOM_KILOGRAM, [GC.FIELD_WEIGHT_UOM], accum);
  const accWeight = R.prop(GC.FIELD_FULL_CONTAINER_WEIGHT, accum);

  if (notEquals(itemWeightUom, accWeightUom)) {
    itemWeight = calcWeightFromTo({
      toFixedNumber: 2,
      to: accWeightUom,
      value: itemWeight,
      from: itemWeightUom,
    });
  }
  const resultWeight = R.add(accWeight, itemWeight);

  return {
    [GC.FIELD_WEIGHT_UOM]: accWeightUom,
    [GC.FIELD_FULL_CONTAINER_WEIGHT]: resultWeight.toFixed(2),
  };
}, {});

export {
  isOdd,
  toFixed,
  toNumber,
  NaNToNull,
  NaNToZero,
  weightToKgs,
  kgsToWeight,
  setPercentage,
  fromMilesToKms,
  fromKmsToMiles,
  getItemsTotals,
  milesKmsFromTo,
  curriedParseInt,
  mathRoundNumber,
  fromKgsToPounds,
  fromPoundsToKgs,
  poundsKgsFromTo,
  calcWeightFromTo,
  getStopItemsInfo,
  toFixedFromString,
  fromKgsToShortTons,
  fromShortTonsToKgs,
  fromKgsToMetricTons,
  fromMetricTonsToKgs,
  calcWeightFromTo100,
  fromKmsToMilesNumber,
  fromMilesToKmsNumber,
  fromCubicFeetToMeter,
  fromCubicMeterToFeet,
  cubicFeetMeterFromTo,
  getLoadTotalDistance,
  fromNillOrEmptyToZero,
  calculateMarginPercent,
  calculateTotalQuantity,
  calculateTotalDistance,
  fromCelsiusToFahrenheit,
  getItemsTotalVolumeInfo,
  getItemsTotalWeightInfo,
  getItemsTotalQuantityInfo,
  convertDistanceAccordingUom,
  calcItemsTotalWeightWithoutQty,
  calculateTotalVolumeWithoutQty,
  getWeightWithUomByConfigUomSystem,
  convertDistanceAccordingUomSystem,
  calcContainersTotalWeightWithoutQty,
  makeTotalPickupQuantityObjectFromItems,
  milesKmsFromToAccordingSystemWithNullable,
};
